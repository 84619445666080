.cart-el-input {
  height: 38px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 10px;
}
.checkbox-inline, .radio-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
  font-family: 'Hiragino Kaku Gothic ProN','Hiragino Sans',Meiryo,sans-serif;
  color: #3a3a3a;
  input {
    position: absolute;
    margin: 6px 0 0;
    margin-left: -20px;
  }
}