.login-wrapper {
  background: #FFFFFF;
  padding: 190px 5px;

  .login-content {
    width: 100%;
    padding: 16px 20px;
    border-radius: 4px;
    border: solid 1px #7070702E;
    box-shadow: #00000029 0 3px 6px;

    .login-title {
      font-size: 20px;
      line-height: 24px;
      color: #000000E6;
    }

    .login-btn {
      width: 100%;
      padding: 10px;
      margin-top: 55px;
      font-size: 16px;
      line-height: 19px;
      height: auto;
      background: #3ACE01;
      color: #FFFFFF;
      border: none;
      border-radius: 4px;
      text-decoration: none;
    }
  }
}
